import {
  ActionTypes,
  TypeAction
} from './request-apparels.actions';
import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store';
import {
  createSelectModelFromAnyArray,
  createSelectModelFromStringArray,
  ICreateSelectModelPropsSetting
} from '../../../shared/components/select/models/createSelectModel';
import { IApparelsViewModel } from '../../../core/services/apparels/interfaces';
import { ISelectOptionModel } from '../../../core/services/interfaces';
import { IOption } from '../../../shared/components/select';

export enum ApparelsStepsEnum {
  apparels,
  delivery,
  confirmation
}

export enum FieldsAsyncValidationEnum {
  pickUpPhoneNumber,
  deliveryPhoneNumber
}

export enum DeliveryTypesEnum {
  pickUp,
  deliveryToHome
}

export interface IFormData {
  shoeSize: string;
  hatSize: string;
  shirtSize: string;
  jacketSize: string;
  pantWaist: string;
  pantLength: string;
  addressLine: string;
  addressLine2: string;
  city: string;
  stateId: string;
  zip: string;
  pickUpPhoneNumber: string;
  deliveryPhoneNumber: string;
  deliveryType: DeliveryTypesEnum;
  initialDelivery: boolean;
}

interface IInitialDeliveryToHomeData {
  addressLine: string;
  addressLine2: string;
  city: string;
  stateId: string;
  zip: string;
  deliveryPhoneNumber: string;
}

export interface IState {
  currentStep: ApparelsStepsEnum;
  initialDeliveryToHomeData: IInitialDeliveryToHomeData;
  recruitApparelsInfo: IApparelsViewModel;
  formData: IFormData;
  editMode: boolean;
  selectOptions: {
    shoeSize: ISelectOptionModel[];
    hatSize: ISelectOptionModel[];
    shirtSize: ISelectOptionModel[];
    jacketSize: ISelectOptionModel[];
    pantWaist: ISelectOptionModel[];
    pantLength: ISelectOptionModel[];
    state: ISelectOptionModel[];
  };
  queryState: string;
  fieldsLoadingProgress: {
    pickUpPhoneNumber: boolean;
    deliveryPhoneNumber: boolean;
  };
  phonesValidity: {
    pickUpPhoneNumber: boolean;
    deliveryPhoneNumber: boolean;
  };
  isLeavingDialogShown: boolean;
}

const initialState: IState = {
  currentStep: ApparelsStepsEnum.apparels,
  selectOptions: {
    shoeSize: [],
    hatSize: [],
    shirtSize: [],
    jacketSize: [],
    pantWaist: [],
    pantLength: [],
    state: []
  },
  recruitApparelsInfo: {
    shoeSize: '',
    hatSize: '',
    shirtSize: '',
    jacketSize: '',
    pantWaist: '',
    pantLength: '',
    deliveryType: DeliveryTypesEnum.deliveryToHome,
    phoneNumber: '',
    addressLine: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: ''
  },
  formData: {
    shoeSize: '',
    hatSize: '',
    shirtSize: '',
    jacketSize: '',
    pantWaist: '',
    pantLength: '',
    pickUpPhoneNumber: '',
    deliveryPhoneNumber: '',
    addressLine: '',
    addressLine2: '',
    city: '',
    stateId: '',
    zip: '',
    deliveryType: DeliveryTypesEnum.deliveryToHome,
    initialDelivery: true
  },
  initialDeliveryToHomeData: {
    deliveryPhoneNumber: '',
    addressLine: '',
    addressLine2: '',
    city: '',
    stateId: '',
    zip: '',
  },
  editMode: false,
  queryState: '',
  fieldsLoadingProgress: {
    pickUpPhoneNumber: false,
    deliveryPhoneNumber: false
  },
  phonesValidity: {
    pickUpPhoneNumber: false,
    deliveryPhoneNumber: false
  },
  isLeavingDialogShown: false
};

export function requestApparelsReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.EDIT_MODE_SWITCH: {
      return Object.assign({}, state, { editMode: payload });
    }
    case ActionTypes.SEARCH_STATE: {
      return Object.assign({}, state, { queryState: payload });
    }
    case ActionTypes.FORM_CONTROL_CHANGE: {
      const { formData }: IState = state;
      const result = Object.assign({}, formData, { [payload.fieldId]: payload.value });

      return Object.assign({}, state, { formData: result });
    }
    case ActionTypes.SET_INFO_TO_FORM: {
      const {
        recruitApparelsInfo,
        formData,
        initialDeliveryToHomeData
      }: IState = state;
      const {
        shoeSize,
        hatSize,
        shirtSize,
        jacketSize,
        pantWaist,
        pantLength,
      } = recruitApparelsInfo;
      const result = {
        ...formData,
        ...initialDeliveryToHomeData,
        pickUpPhoneNumber: initialDeliveryToHomeData.deliveryPhoneNumber,
        shoeSize,
        hatSize,
        shirtSize,
        jacketSize,
        pantWaist,
        pantLength,
      };

      return Object.assign({}, state, { formData: result });
    }
    case ActionTypes.STORE_APPARELS_INFO: {
      return Object.assign({}, state, { recruitApparelsInfo: payload });
    }
    case ActionTypes.STORE_APPARELS_OPTIONS: {
      return Object.assign({}, state, { selectOptions: payload });
    }
    case ActionTypes.SET_CURRENT_FORM_STEP: {
      return Object.assign({}, state, { currentStep: payload });
    }
    case ActionTypes.SET_LEAVING_DIALOG_STATE: {
      return Object.assign({}, state, { isLeavingDialogShown: payload });
    }
    case ActionTypes.PRESET_INITIAL_DELIVERY_DATA: {
      return Object.assign({}, state, {
        formData: {
          ...state.formData,
          ...state.initialDeliveryToHomeData
        }
      });
    }
    case ActionTypes.PREPOPULATE_DELIVERY_DATA: {
      return Object.assign({}, state, {
        initialDeliveryToHomeData: {
          ...payload
        },
        formData: {
          ...state.formData,
          ...payload
        }
      });
    }
    case ActionTypes.RESET_PAGE_STATE: {
      return Object.assign({}, initialState);
    }

    case ActionTypes.SET_FIELD_LOADING_STATE: {
      const { fieldsLoadingProgress }: IState = state;
      const changes = {
        ...fieldsLoadingProgress,
        [FieldsAsyncValidationEnum[payload.field]]: payload.isLoading
      };

      return Object.assign({}, state, { fieldsLoadingProgress: changes });
    }

    case ActionTypes.SET_PHONE_VALIDATION: {
      const { phonesValidity }: IState = state;
      const changes = {
        ...phonesValidity,
        [FieldsAsyncValidationEnum[payload.field]]: payload.valid
      };

      return Object.assign({}, state, { phonesValidity: changes });
    }
    default: {
      return state;
    }
  }
}

export const getApparelsFormData = createSelector(
  ({ requestApparels }: IRootState): IState => requestApparels,
  ({ formData }: IState): IFormData => formData
);

export const getIsApparelsInEditMode = createSelector(
  ({ requestApparels }: IRootState): IState => requestApparels,
  ({ editMode }: IState): boolean => editMode
);

export const getRecruitApparelsInfo = createSelector(
  ({ requestApparels }: IRootState): IState => requestApparels,
  ({ recruitApparelsInfo }: IState): IApparelsViewModel => recruitApparelsInfo
);

export const getApparelsSelectModels = createSelector(
  ({ requestApparels }: IRootState): IState => requestApparels,
  ({ selectOptions }: IState): any => {
    const apparelsSelectSetting: ICreateSelectModelPropsSetting = {
      valueKey: 'text',
      labelKey: 'text'
    };
    const stateSelectSetting: ICreateSelectModelPropsSetting = {
      valueKey: 'value',
      labelKey: 'text'
    };
    const { state, ...apparels } = selectOptions;

    return Object.keys(apparels).reduce((acc, key) => {
      acc[key] = typeof apparels[key][0] !== 'object'
        ?
        createSelectModelFromStringArray(apparels[key])
        :
        createSelectModelFromAnyArray(apparels[key], apparelsSelectSetting);

      return acc;
    }, Object.assign({
      state: createSelectModelFromAnyArray(state, stateSelectSetting)
    }, apparels));
  }
);

export const getCurrentStepString = createSelector(
  ({ requestApparels }: IRootState): IState => requestApparels,
  ({ currentStep }: IState): string => ApparelsStepsEnum[currentStep]
);

export const getIsLeavingDialogShown = createSelector(
  ({ requestApparels }: IRootState): IState => requestApparels,
  ({ isLeavingDialogShown }: IState): boolean => isLeavingDialogShown
);

export const getIsFieldLoading = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ requestApparels }: IRootState) => requestApparels,
  ({ fieldsLoadingProgress }: IState) => fieldsLoadingProgress[FieldsAsyncValidationEnum[field]]
);

export const getPhoneValidity = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ requestApparels }: IRootState) => requestApparels,
  ({ phonesValidity }: IState) => phonesValidity[FieldsAsyncValidationEnum[field]]
);

export const getFilteredStates = createSelector(
  ({ requestApparels }: IRootState) => requestApparels,
  ({
    selectOptions,
    queryState
  }: IState): IOption[] => {
    return selectOptions.state
      .filter((state) => state.text
        .toLowerCase()
        .includes(queryState.toLowerCase()))
      .map((school) => ({
        value: school.value,
        label: school.text
      }));
  }
);
