import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IFormInputChangePayload } from '../../abstract.components/abstract.form.component';
import {
  ApparelsStepsEnum,
  FieldsAsyncValidationEnum
} from './request-apparels.reducer';
import { ValidationTypesEnum } from '../../../core/services/employee/employee.service';

export const ActionTypes = {
  FETCH_APPARELS_INFO: type('[Apparels] fetch apparels info'),
  FETCH_APPARELS_ADDRESS: type('[Apparels] fetch apparels address'),
  FETCH_APPARELS_OPTIONS: type('[Apparels] fetch apparels options'),
  STORE_APPARELS_INFO: type('[Apparels] store apparels info'),
  STORE_APPARELS_OPTIONS: type('[Apparels] store apparels options'),
  EDIT_MODE_SWITCH: type('[Apparels] switch edit mode'),
  FORM_CONTROL_CHANGE: type('[Apparels] form control change'),
  SET_INFO_TO_FORM: type('[Apparels] set apparels info to form'),
  REQUEST_APPARELS: type('[Apparels] submit apparels request'),
  UPDATE_APPARELS: type('[Apparels] update apparels request'),
  SEARCH_STATE: type('[Apparels] search state'),
  SET_CURRENT_FORM_STEP: type('[Apparels] set current form step'),
  SET_FIELD_LOADING_STATE: type('[Apparels] set field loading state'),
  VALIDATE_PHONE: type('[Apparels] validate phone'),
  SET_PHONE_VALIDATION: type('[Apparels] set phone validation'),
  PRESET_INITIAL_DELIVERY_DATA: type('[Apparels] preset initial delivery data'),
  TOGGLE_DELIVERY_CHECKBOX: type('[Apparels] toggle delivery checkbox'),
  SET_LEAVING_DIALOG_STATE: type('[Apparels] set leaving dialog state'),
  LEAVING_CONFIRMATION: type('[Apparels] confirm leaving'),
  PREPOPULATE_DELIVERY_DATA: type('[Apparels] prepopulate delivery data'),
  RESET_PAGE_STATE: type('[Apparels] reset page state'),
};

export class FetchApparelsInfo implements Action {
  public type = ActionTypes.FETCH_APPARELS_INFO;

  constructor(public payload?: any) {}
}

export class FetchApparelsOptions implements Action {
  public type = ActionTypes.FETCH_APPARELS_OPTIONS;

  constructor(public payload?: any) {}
}

export class StoreApparelsRequestInfo implements Action {
  public type = ActionTypes.STORE_APPARELS_INFO;

  constructor(public payload?: any) {}
}

export class StoreApparelsOptions implements Action {
  public type = ActionTypes.STORE_APPARELS_OPTIONS;

  constructor(public payload: any) {}
}

export class ApparelsSwitchEditMode implements Action {
  public type = ActionTypes.EDIT_MODE_SWITCH;

  constructor(public payload: boolean) {}
}

export class ApparelsFormControlChange implements Action {
  public type = ActionTypes.FORM_CONTROL_CHANGE;

  constructor(public payload: IFormInputChangePayload) {}
}

export class SetApparelsInfoToForm implements Action {
  public type = ActionTypes.SET_INFO_TO_FORM;

  constructor(public payload?: any) {}
}

export class SubmitApparelsRequest implements Action {
  public type = ActionTypes.REQUEST_APPARELS;

  constructor(public payload?: any) {}
}

export class ApparelsSearchState implements Action {
  public type = ActionTypes.SEARCH_STATE;

  constructor(public payload: string) {}
}

export class SetCurrentFormStep implements Action {
  public type = ActionTypes.SET_CURRENT_FORM_STEP;

  constructor(public payload: ApparelsStepsEnum) {}
}

export class SetFieldLoadingState implements Action {
  public type = ActionTypes.SET_FIELD_LOADING_STATE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    isLoading: boolean
  }) {}
}

export class ValidatePhone implements Action {
  public type = ActionTypes.VALIDATE_PHONE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    phone: string
  }) {}
}

export class SetPhoneValidation implements Action {
  public type = ActionTypes.SET_PHONE_VALIDATION;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    valid: ValidationTypesEnum
  }) {}
}

export class DisableDeliveryDataEdit implements Action {
  public type = ActionTypes.PRESET_INITIAL_DELIVERY_DATA;

  constructor(public payload?: any) {}
}

export class EnableDeliveryDataEdit implements Action {
  public type = ActionTypes.TOGGLE_DELIVERY_CHECKBOX;

  constructor(public payload?: any) {}
}

export class SetLeavingDialogState implements Action {
  public type = ActionTypes.SET_LEAVING_DIALOG_STATE;

  constructor(public payload: boolean) {}
}

export class LeavingConfirmation implements Action {
  public type = ActionTypes.LEAVING_CONFIRMATION;

  constructor(public payload: boolean) {}
}

interface IDeliveryData {
  pickUpPhoneNumber: string;
  deliveryPhoneNumber: string;
  addressLine: string;
  addressLine2: string;
  city: string;
  stateId: string | number;
  zip: string;
}

export class PrepopulateDeliveryData implements Action {
  public type = ActionTypes.PREPOPULATE_DELIVERY_DATA;

  constructor(public payload: IDeliveryData) {}
}

export class ResetPageState implements Action {
  public type = ActionTypes.RESET_PAGE_STATE;

  constructor(public payload?: any) {}
}

export type TypeAction =
  ApparelsSwitchEditMode |
  ApparelsFormControlChange |
  SetApparelsInfoToForm |
  StoreApparelsRequestInfo |
  StoreApparelsOptions |
  ApparelsSearchState |
  SetCurrentFormStep |
  SetFieldLoadingState |
  ValidatePhone |
  SetPhoneValidation |
  DisableDeliveryDataEdit |
  EnableDeliveryDataEdit |
  SetLeavingDialogState |
  LeavingConfirmation |
  PrepopulateDeliveryData |
  ResetPageState;
