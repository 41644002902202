import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';

import { Observable} from 'rxjs';
import { AppService } from '../../../../app.service';

@Injectable()
export class OptionsService extends AbstractServerInteractionService {
  protected get url(): {
    // eslint-disable-next-line @typescript-eslint/ban-types
    state: Function
  } {
    return this.appService.getApiUrls().options;
  }

  constructor(
    protected appService: AppService,
    protected http: HttpClient
  ) {
    super();
  }

  public getStateOptions(country): Observable<any> {
    return this.http.get(this.url.state(country));
  }
}
