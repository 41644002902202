import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';

import { Observable, map } from 'rxjs';
import {
  IApparelsOptionsResponse,
  IApparelsViewModel
} from './interfaces';
import {
  DeliveryTypesEnum,
  IFormData
} from '../../../common/tasks/request-apparels/request-apparels.reducer';
import {
  formatPostalCode,
  removeSpacesFromString
} from '../../../util';
import { AppService } from '../../../../app.service';

@Injectable()
export class ApparelsService extends AbstractServerInteractionService {
  protected get url(): {
    options: string,
    info: string
  } {
    return this.appService.getApiUrls().apparels;
  }

  constructor(
    protected appService: AppService,
    protected http: HttpClient) {
    super();
  }

  public getOptions(): Observable<IApparelsOptionsResponse | any> {
    return this.http.get(this.url.options);
  }

  public getApparelsInfo(): Observable<IApparelsViewModel | any> {
    return this.http.get(this.url.info).pipe(map((info: any) => ({
      ...info,
      zip: formatPostalCode(info.zip)
      })));
  }

  public submitApparels(formData: IFormData, isCanada: boolean) {
    return this.http.post(this.url.info, ApparelsService.prepareFormDataToSend(formData, isCanada));
  }

  public static prepareFormDataToSend(formData: IFormData, isCanada) {
    return {
      shoeSize: formData.shoeSize,
      hatSize: formData.hatSize,
      shirtSize: formData.shirtSize,
      jacketSize: formData.jacketSize,
      pantWaist: formData.pantWaist,
      pantLength: formData.pantLength,
      deliveryType: formData.deliveryType,
      phoneNumber: formData.deliveryType === DeliveryTypesEnum.deliveryToHome
        ? formData.deliveryPhoneNumber
        : formData.pickUpPhoneNumber,
      addressLine: formData.addressLine,
      addressLine2: formData.addressLine2,
      city: formData.city,
      stateId: isCanada ? null : formData.stateId,
      provinceId: isCanada ? formData.stateId : null,
      zip: removeSpacesFromString(formData.zip),
    };
  }
}
